<template>
    <div>
        <jy-query ref="form" :model="form">
            <jy-query-item prop="routeNu" label="线路编号:">
                <el-input placeholder="请输入" v-model="form.routeNu"></el-input>
            </jy-query-item>
            <jy-query-item prop="cName" label="线路名称:">
                <el-input placeholder="请输入" v-model="form.cName"></el-input>
            </jy-query-item>
            <template slot="rightCol">
                <el-button type="primary" @click="oncheck" v-if="btnexist('scheduleBasicRouteSiteList')">查询</el-button>
                <el-button type="primary" @click="resetForm('form')" v-if="btnexist('scheduleBasicRouteSiteReset')">重置</el-button>
                <el-row>
                    <el-button type="primary" @click="importFun" v-if="btnexist('scheduleBasicRouteSiteImport')">导入</el-button>
                </el-row>
            </template>
        </jy-query>
        <jy-table ref="jyTable" :data="dataList">
            <jy-table-column type="index" label="序号" width="60"></jy-table-column>
            <jy-table-column prop="routeNu" label="线路编号"></jy-table-column>
            <jy-table-column prop="cName" label="线路名称"></jy-table-column>
            <jy-table-column label="线路类型">
                <template slot-scope="scope">
                    {{scope.row.rType|xltype}}
                </template>
            </jy-table-column>
            <jy-table-column prop="orgNa" label="所属机构"></jy-table-column>
            <jy-table-column prop="upNum" label="上行/环形站点数"></jy-table-column>
            <jy-table-column prop="downNum" label="下行站点数"></jy-table-column>
            <jy-operate v-bind:list="operateList" width="200"></jy-operate>
        </jy-table>
        <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total"></jy-pagination>
        <detail ref="detail"></detail>
        <jy-import ref="jyImport" :headers="uploadHeaders" :action="action" @downloadTemplate="downloadTemplate" @getElxData="getElxData" @confirm="confirm" :successLength="correctCount" :errList="listError" :status.sync="importStatus"></jy-import>
    </div>
</template>
<script>
import detail from '@/components/pages/admin/resourcesAllocation/lineSiteQuery/lineSitedetail'
import {btnMixins} from '@/common/js/button.mixin'
export default {
    data() {
        return {
            form: {
                cName: '',
                routeNu: '',
            },
            pageSize: 10,
            total: 0,
            pageIndex: 1,
            // 列表列数据
            dataList: [],
            //  操作
            operateList: [],

            //导入
            uploadHeaders: {
                token: this.$util.getStor('token'),
            },
            action: '/IntelligentArrangeSystem/route/site/importSitesCach',
            importId: '',
            listError: [],
            correctCount: '',
            errorCount: '',

            importStatus: 1,

            btnMenuId:'scheduleBasicRouteSite',
        }
    },
    mixins:[btnMixins],
    components: {
        detail,
    },
    filters: {
        xltype(val) {
            if (val == '0') {
                return '上下行'
            } else {
                return '环形'
            }
        },
    },
    created() {
        this.getList()
        this.setOperateList()
    },
    methods: {
        setOperateList() {
            let l = [
                {
                    name: '站点明细',
                    icon: 'el-icon-delete',
                    fun: this.addUdate,
                    isShow:()=>{
                        return this.btnexist('scheduleBasicRouteSiteDetail')
                    },
                },
                {
                    name: '删除',
                    icon: 'el-icon-delete',
                    fun: this.handleDelete,
                    isShow:()=>{
                        return this.btnexist('scheduleBasicRouteSiteDelete')
                    },
                },
            ]
            this.operateList = l
        },
        getList() {
            let option = {
                ...this.form,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
            }
            let url = '/route/site/queryPage'
            // console.log(option)
            this.$http.post(url, option).then(res => {
                let list = res.detail.list.map(item=>{
                    return {
                        ...item,
                        ...item.sysOrg,
                        ...item.routeInfo,
                    }
                })
                this.dataList = list
                this.total = res.detail.total
            })
        },
        // 查询表格
        oncheck() {
            this.pageIndex = 1
            this.getList()
            console.log('更新')
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields()
            this.oncheck()
        },
        // 分页
        handleSizeChange(val) {
            this.pageSize = val
            this.getList()
        },
        handleCurrentChange(val) {
            this.pageIndex = val
            this.getList()
        },
        addUdate(option) {
            if(this.btnexist('scheduleBasicRouteSiteExport')) {
                option.export = true
            } else {
                option.export = false
            }
            this.$refs.detail.init(option)
        },
        // 删除
        handleDelete(row) {
            this.$confirm('是否删除该线路?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let option ={routeId:row.routeId}
                    this.deleteRecord(option)
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    })
                })
        },
        deleteRecord(supplierIdAry) {
            this.$http
                .post('/route/site/delByRouteId', supplierIdAry)
                .then(data => {
                    if (data.code == '0') {
                        this.$message.success(data.msg)
                        this.getList()
                    } else {
                        this.$message.error(data.msg)
                    }
                })
        },
        // 批量删除
        batchdelete() {
            if (this.batchdeleteList.length == 0) {
                this.$message({
                    type: 'warning',
                    message: '请先选取数据',
                })
            } else {
                this.$confirm('是否删除所选中的记录?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                })
                    .then(() => {
                        let option = this.batchdeleteList
                        this.deleteRecord(option)
                    })
                    .catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消删除',
                        })
                    })
            }
        },

        //导入
        importFun() {
            this.$refs.jyImport.init()
        },
        downloadTemplate() {
            let url = '/route/site/downloadTemplate'
            this.$http
                .post(url, {}, { type: 'format', isExcel: true })
                .then(res => {
                    this.$util.funDownload(res.data, '线路站点模板')
                })
        },
        getElxData(data) {
            console.log(data.detail)
            this.importId = data.detail.importId
            let url = '/route/site/importSitesCheck'
            let params = {
                importId: data.detail.importId,
            }
            this.$http.post(url, params).then(data => {
                let { detail } = data
                this.listError = detail.listError
                this.correctCount = detail.correctCount
                this.errorCount = detail.errorCount
            })
        },
        confirm() {
            let params = {
                importId: this.importId,
            }
            let url = '/route/site/importSitesData'
            this.$http.post(url, params).then(
                data => {
                    if (data.code == 0) {
                        this.importStatus = 1
                        this.oncheck()
                    }
                },
                () => {
                    this.importStatus = -1
                }
            )
        },
    },
}
</script>
